.App {
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 8vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.urls{
  list-style: none;
  margin: 0;
  padding: 1rem;
  margin-top: 2rem;
  
  border-radius: 4px;
  box-shadow: 1px 1px 4px #ccc;
}

ul li:last-child{
 
  border-radius: 4px;
}

button{
  margin-top: 0.4rem;
  margin-bottom: 0.4rem;
}

label{
  font-size: 12px;
  font-weight: 600;
  text-transform:uppercase;
}

.form-group{
  margin-top: 1rem;
  box-shadow: 1px 1px 4px #ccc;
  padding: 0.4rem;
}

.btn-container{
  box-shadow: 1px 1px 4px #ccc;
  padding: 0.4rem;
  margin-top: 1rem;
}

.shortlink-container {
  display: flex;
  box-shadow: 1px 1px 4px #ccc;
  padding: 0.4rem;
  background-color: #fcfcfc;
}
.shortlink-container input {
  border: none;
}